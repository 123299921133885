import { conditionVariables } from '../constants/rules';

export const entityTypes: {
  [key: string]: {
    shortName?: string;
    color: string;
    borderColor: string;
    bgColor: string;
    fontColor: string;
    colorHex: string;
    ringColor: string;
  };
} = {
  Exchange: {
    color: 'purple-500',
    borderColor: 'border-purple-500',
    bgColor: 'bg-purple-500',
    fontColor: 'text-white',
    colorHex: '#8b5cf6',
    ringColor: 'ring-purple-500',
  },
  'Mining Pool': {
    color: 'text-purple-400',
    borderColor: 'border-purple-400',
    bgColor: 'bg-purple-400',
    fontColor: 'text-white',
    colorHex: '#a78bfa',
    ringColor: 'ring-purple-400',
  },
  Service: {
    color: 'indigo-500',
    borderColor: 'border-indigo-500',
    bgColor: 'bg-indigo-500',
    fontColor: 'text-white',
    colorHex: '#6366F1',
    ringColor: 'ring-indigo-500',
  },
  Darknet: {
    color: 'text-pink-700',
    borderColor: 'border-pink-700',
    bgColor: 'bg-pink-700',
    fontColor: 'text-white',
    colorHex: '#be185d',
    ringColor: 'ring-pink-700',
  },
  Gambling: {
    color: 'purple-700',
    borderColor: 'border-purple-700',
    bgColor: 'bg-purple-700',
    fontColor: 'text-white',
    colorHex: '#6d28d9',
    ringColor: 'ring-purple-700',
  },
  'Coin Mixer': {
    color: 'purple-900',
    borderColor: 'border-purple-900',
    bgColor: 'bg-purple-900',
    fontColor: 'text-white',
    colorHex: '#4c1d95',
    ringColor: 'ring-purple-900',
  },
  Scam: {
    color: 'pink-500',
    borderColor: 'border-pink-500',
    bgColor: 'bg-pink-500',
    fontColor: 'text-white',
    colorHex: '#ec4899',
    ringColor: 'ring-pink-500',
  },
  Extortion: {
    color: 'pink-300',
    borderColor: 'border-pink-300',
    bgColor: 'bg-pink-300',
    fontColor: 'text-white',
    colorHex: '#f9a8d4',
    ringColor: 'ring-pink-300',
  },
  Malware: {
    color: 'rose-500',
    borderColor: 'border-rose-500',
    bgColor: 'bg-rose-500',
    fontColor: 'text-white',
    colorHex: '#f43f5e',
    ringColor: 'ring-rose-500',
  },
  Theft: {
    color: 'rose-400',
    borderColor: 'border-rose-400',
    bgColor: 'bg-rose-400',
    fontColor: 'text-white',
    colorHex: '#fb7185',
    ringColor: 'ring-rose-400',
  },
  Donation: {
    color: 'sky-700',
    borderColor: 'border-sky-700',
    bgColor: 'bg-sky-700',
    fontColor: 'text-white',
    colorHex: '#0369a1',
    ringColor: 'ring-sky-700',
  },
  'Smart Contract Platform': {
    shortName: 'Smart Contract',
    color: 'sky-300',
    borderColor: 'border-sky-300',
    bgColor: 'bg-sky-300',
    fontColor: 'text-white',
    colorHex: '#7dd3fc',
    ringColor: 'ring-sky-300',
  },
  Sanctions: {
    color: 'pink-900',
    borderColor: 'border-pink-900',
    bgColor: 'bg-pink-900',
    fontColor: 'text-white',
    colorHex: '#831843',
    ringColor: 'ring-pink-900',
  },
  'High Risk Organization': {
    color: 'rose-700',
    borderColor: 'border-rose-700',
    bgColor: 'bg-rose-700',
    fontColor: 'text-white',
    colorHex: '#be123c',
    ringColor: 'ring-rose-700',
  },
  'Law Enforcement': {
    color: 'sky-900',
    borderColor: 'border-sky-900',
    bgColor: 'bg-sky-900',
    fontColor: 'text-white',
    colorHex: '#0c4a6e',
    ringColor: 'ring-sky-900',
  },
  DeFi: {
    color: 'sky-500',
    borderColor: 'border-sky-500',
    bgColor: 'bg-sky-500',
    fontColor: 'text-white',
    colorHex: '#0ea5e9',
    ringColor: 'ring-sky-500',
  },
  Others: {
    color: 'gray-500',
    borderColor: 'border-gray-500',
    bgColor: 'bg-gray-500',
    fontColor: 'text-white',
    colorHex: '#6b7280',
    ringColor: 'ring-gray-500',
  },
};

export const entityDescriptions = {
  Exchange: {
    'Mandatory KYC and AML': 'Exchanges that do not allow withdrawals without KYC verification of customers',
    'Optional KYC and AML':
      'Exchanges only need KYC for a portion of their users, such as those requiring a daily withdrawal amount over a certain threshold.',
    Inactive: 'Exchanges that are no longer being used for cryptocurrency trade.',
    'Decentralized (DEX)':
      'A decentralized exchange (DEX) is a cryptocurrency exchange that operates without a centralized authority. They employ smart contracts to match suppliers and buyers for trading.',
    P2P: "A peer-to-peer (P2P) exchange is a platform that allows two people to communicate directly with one another without the use of a third party. The P2P service allows the buyer and seller to trade directly with one another,  enabling direct transfers of crypto/fiat between a user's deposit address and their bank account.",
    OTC: 'The trading of cryptocurrencies/FIAT between two counterparties outside of a marketplace network is known as over-the-counter (OTC). OTC trades are made directly between two parties, as opposed to trading on an exchange.',
  },
  'Mining Pool': {
    'Mining Pool': 'Entities whose primary purpose is to pool hashing power to mine cryptocurrencies.',
  },
  Service: {
    'Payment Gateway': 'Services that aid in the acceptance of cryptocurrency payments.',
    'Financial Service':
      'Services like crypto loans, escrow services, and other crypto financial services are provided by entities. However, a central authority manages and controls these services.',
    'Wallet Provider': 'Services that allow users to store cryptocurrency in wallets',
    Marketplace:
      'Services that enable users to trade cryptocurrency for products or services from a variety of suppliers',
    Shop: 'Users can trade bitcoin for products or services from a single seller using these services.',
    'Bitcoin Faucet':
      'Services that reward users with cryptocurrency when they accomplish specific activities, like captchas or viewing advertisements.',
    Custody:
      'Services which are third party providers of storage and security services for cryptocurrencies. These are mainly aimed at institutional investors, such as hedge funds, who hold large amounts of bitcoin or other cryptocurrencies.',
    'Cryptocurrency ATM':
      'Services which allow users to buy Bitcoins and other cryptocurrency using cash or a debit card',
    'Decentralized Finance (DeFi)':
      'Services that enable users to engage with one another using smart contracts give rise to new financial services.',
  },
  Darknet: {
    'Child Sexual Abuse Material Vendor':
      "Entities who run websites that contain children's sexual material.",
    'Hitman Services':
      'Entities that provide services in which one party pays a third party (commonly referred to as a hitman) to kill a specific person or group of individuals.',
    'Stolen Accounts Vendors': 'Entities that operate websites selling stolen account information.',
    'Human Trafficking':
      'Entities that trade people for forced labor, sexual slavery, or commercial sexual exploitation.',
    'Compromised Credit Cards': "Websites that sell people's credit cards have been hacked.",
    'Narcotics and Controlled Substances Vendor':
      'Websites offer illicit drugs and controlled substances such as marijuana, ecstasy, cocaine, prescription medications, and other restricted substances.',
    'Weapons Vendor': 'Entities that run websites that sell illegal weapons and ammunition illegally.',
    'Darknet Vendor Shop':
      'Services seen on the dark web allow users to exchange cryptocurrency for goods or services offered by a single seller.',
    'Darknet Marketplace':
      'Websites that allow users to trade cryptocurrency for products or services from numerous vendors on the dark web.',
    'Darknet Donation': 'Entities that have requested donations and are seen on the dark web.',
    'Adult Content': 'Entities which operate websites selling adult videos (pornographic content).',
  },
  'High Risk Organization': {
    'OFAC Cluster':
      'Cryptocurrency Addresses are operated by the same company or entities close to addresses on the OFAC blacklist.',
    'Terrorist Organization':
      'Terrorist-affiliated organisations that hold or manage cryptocurrency addresses.',
    'Criminal Organization':
      'Cryptocurrency addresses that have been designated as belonging to or controlled by criminal organizations.',
    'Frozen Assets':
      'Addresses of assets confiscated due to improper use of Crypto tokens or in response to requests from law enforcement authorities and large financial institutions.',
    'Ukraine Entities': 'Addresses which are owned by entities operating in Ukraine',
    'High Risk Jurisdictions':
      'Entities that belong to countries that have comprehensive sanctions and entities that are NOT Exchanges but belong to Russian region.',
    'High Risk Exchanges':
      'High Risk Exchanges are exchanges that have potential compliance and due diligence shortcomings.',
  },
  Gambling: {
    'Fiat and Crypto': 'Gambling companies that accept both regular fiat cash and cryptocurrency.',
    'Crypto only':
      'Gambling establishments that accept only cryptocurrency deposits and Cryptocurrency withdraws.',
  },
  'Coin Mixer': {
    Decentralized:
      'A coins Bitcoin mixer is a decentralized Bitcoin mixing technology that enables users to use Bitcoin anonymously. Wasabi Wallet, Whirlpool, and JoinMarket are examples of Coin Mixers that conduct mixing without assuming custody of the coin.',
    Centralized:
      'Centralized coin mixers are for-profit businesses that receive your coin and return other currencies in exchange for a charge.',
  },
  Scam: {
    Phishing:
      'Cryptocurrency addresses belonging to companies involved in phishing, a form of social engineering attack frequently used to obtain user data such as login credentials and credit card details.',
    Spam: 'Cryptocurrency addresses are used by companies who send unauthorized messages to distribute malware or phishing to steal personal and financial information.',
    'High Yield Investment Program':
      'Cryptocurrency addresses belonging to entities participating in a fraudulent investment plan that promises very high returns on investment, such as Ponzi scams, bitcoin doubler schemes, and so on.',
    'Exit Scam':
      "Exit scam addresses are cryptocurrency addresses owned by companies participating in a fraudulent operation conducted by cryptocurrency promoters who abscond with investors' cash during or after an initial coin offering (ICO).",
    'Fake ICO Scam':
      "Cryptocurrency addresses are controlled by fraudsters who spend a little on a professional-looking website and marketing materials, such as a white paper, to gain investors' trust and steal their money.",
    'Impersonation Scam':
      'Cryptocurrency addresses are used by entities pretending to be someone else, such as a high-profile celebrity, a corporate employee, or a bitcoin exchange, asking for private information or cryptocurrency payments.',
    'Fake service':
      'Cryptocurrency addresses owned by entities which operates fake services to steal money from their users illegally.',
  },
  Extortion: {
    Sextortion:
      "Cryptocurrency addresses are controlled by entities who scam people after stating that the victim's digital device has been compromised and threatening to expose the victim's compromised information/recording unless a ransom is paid in Cryptocurrency.",
    Blackmail:
      'Cryptocurrency addresses are owned by entities involved in threatening people in return for cryptocurrency, mainly bitcoin, as a form of extortion.',
  },
  Malware: {
    Ransomware:
      'Crypto-ransomware is a form of malicious software that encrypts data and files on a computer or mobile device to extract cryptocurrencies or money.',
    Cryptojacking:
      'Cryptojacking is when thieves utilize an illegal computer, tablet, mobile phone, or connected home device to mine cryptocurrencies.',
  },
  Theft: {
    Hack: 'Entities that gained control of cryptocurrencies through social engineering or cybersecurity assaults.',
    Abuse:
      'Entities who gained control of cryptocurrency through poorly drafted smart contracts or exploiting various web platform vulnerabilities.',
  },
  Donation: {
    'Charity (Political)':
      'Donations are paid to a political trust or organization, as well as money donated to political campaigns.',
    'Charity (Non-political)': "Donations are made to a non-political trust or organization's funding.",
    'Project Donation': "Donations are given in support of a project's funding.",
    'Tip Jar': 'Money donated to people on social networking sites.',
  },
  'Smart Contract Platform': {
    ICO: 'Cryptocurrency addresses are controlled by companies that accept cryptocurrency investments as a type of crowdfunding. For example, in an ICO, a certain amount of cryptocurrency is offered to prospective purchasers in the form of "tokens" or "coins" in return for legal cash or other (more established and stable) cryptocurrencies like Bitcoin or Ethereum.',
    DAPP: 'Cryptocurrency addresses decentralized apps that run on a peer-to-peer distributed network rather than on a single computer. Thus, there is no single entity in charge.',
    Token:
      'To govern the issue of bitcoins, cryptocurrency addresses were developed. Cryptocurrencies, also known as crypto tokens or crypto assets, are a form of virtual money based on the blockchain that can signify a purchase or service.',
  },
  Sanctions: {
    OFAC: 'Cryptocurrency addresses listed on Office of Foreign Asset Controls (OFAC) blacklist.',
    'Entity from Sanctioned Country':
      'Cryptocurrency address belongs an entity based in a country which has international economic sanctions imposed against them, which typically is a ban on trade, possibly limited to certain sectors such as armaments, or with certain exceptions',
    'OFAC Cluster':
      'Cluster of the cryptocurrency addresses which are under sanctions list (ex OFAC Sanctioned addresses)',
  },
  DeFi: {
    'Decentralized Exchange - AMM':
      'A decentralized trading protocol that prices assets using a mathematical formula. Assets are valued using a pricing algorithm rather than an order book like a typical exchange. Uniswap, Sushiswap, and other DEXs that follow AMM are examples.',
    'Decentralized Exchange - Aggregator':
      'A decentralized exchange gathers trading pairs from several DEXes to offer the best price to users, allowing crypto traders to access a variety of trading pools from a single dashboard. 1inch is a DEX aggregator example.',
    'Lending/Borrowing':
      'Users can lend/borrow their assets to others on decentralized platforms in return for interest payments received or paid. Examples are Aave, compound, MakerDao, and others.',
    Insurance:
      'Decentralized platforms that protect their customers against DAO attacks, Parity multi-sig wallet problems, smart contract hacking, and stable coin default, among other things. Nexus Mutual, Opium Insurance, and other companies are examples.',
    'Yield Aggregator':
      'A platform that maximizes user revenues by utilizing various DeFi protocols and techniques. Yield aggregators include companies like Harvest Finance.',
    Bridges:
      'Users can transfer funds from one blockchain to another using this protocol. A good example using this protocol is Anyswap.',
    'Asset Management':
      'This protocol provides asset management for tokenized assets. Examples of this are Set protocol and the value Defi.',
    Derivatives:
      'Decentralized derivatives of any sort (Tokens whose value is derived from the performance of an underlying asset, such as stock). Augur, synthetix, Uma, and more examples',
  },
  'Law Enforcement': {
    'Seized Coins':
      "The proceeds of a computer intrusion and property used in money laundering are represented by these coins/tokens, taken under criminal and civil forfeiture legislation. Law enforcement agencies, such as the FBI, have confiscated cryptocurrency coins/tokens transferred from the criminal's wallet to the law enforcement's wallet.",
  },
  Others: {
    Others:
      'Mostly individuals that hold cryptoasssets and do not belong to any VASPs/services or other subtypes defined by Merklescience.',
  },
};
export const getEntityTypeBySubType = (entitySubType: string) =>
  Object.keys(entityDescriptions).find((e) =>
    Object.keys(entityDescriptions[e]).find((s) => s === entitySubType)
  );
export const entityTypeIds = {
  '1': 'Exchange',
  '2': 'Mining Pool',
  '3': 'Service',
  '4': 'Darknet',
  '5': 'Gambling',
  '6': 'Coin Mixer',
  '7': 'Scam',
  '8': 'Extortion',
  '9': 'Malware',
  '10': 'Theft',
  '11': 'Donation',
  '12': 'Smart Contract Platform',
  '13': 'Sanctions',
  '14': 'High Risk Organization',
  '15': 'Law Enforcement',
  '16': 'DeFi',
  '17': 'Others',
};

export const entitySubTypeIds = {
  '101': 'Mandatory KYC and AML',
  '102': 'Optional KYC and AML',
  '103': 'Decentralized (DEX)',
  '104': 'P2P',
  '105': 'OTC',
  '106': 'Inactive',
  '201': 'Mining Pool',
  '301': 'Payment Gateway',
  '302': 'Financial Service',
  '303': 'Wallet Provider',
  '304': 'Marketplace',
  '305': 'Shop',
  '306': 'Bitcoin Faucet',
  '307': 'Decentralized Finance (DeFi)',
  '308': 'Custody',
  '309': 'Cryptocurrency ATM',
  '401': 'Child Sexual Abuse Material Vendor',
  '402': 'Hitman Services',
  '403': 'Stolen Accounts Vendors',
  '404': 'Human Trafficking',
  '405': 'Compromised Credit Cards',
  '406': 'Narcotics and Controlled Substances Vendor',
  '407': 'Weapons Vendor',
  '408': 'Darknet Vendor Shop',
  '409': 'Darknet Marketplace',
  '410': 'Darknet Donation',
  '411': 'Adult Content',
  '501': 'Fiat and Crypto',
  '502': 'Crypto only',
  '601': 'Centralized',
  '602': 'Decentralized',
  '701': 'Phishing',
  '702': 'Spam',
  '703': 'High Yield Investment Program',
  '704': 'Exit Scam',
  '705': 'Fake ICO Scam',
  '706': 'Impersonation Scam',
  '707': 'Fake service',
  '801': 'Sextortion',
  '802': 'Blackmail',
  '901': 'Ransomware',
  '902': 'Cryptojacking',
  '1001': 'Hack',
  '1002': 'Abuse',
  '1101': 'Charity (Non-political)',
  '1102': 'Project Donation',
  '1103': 'Charity (Political)',
  '1104': 'Tip Jar',
  '1201': 'ICO',
  '1202': 'DAPP',
  '1203': 'Token',
  '1301': 'OFAC',
  '1302': 'OFAC Cluster',
  '1303': 'Entity from Sanctioned Country',
  '1401': 'OFAC Cluster',
  '1402': 'Terrorist Organization',
  '1403': 'Criminal Organization',
  '1404': 'Frozen Assets',
  '1405': 'Ukraine Entities',
  '1406': 'High Risk Jurisdictions',
  '1407': 'High Risk Exchanges',
  '1501': 'Seized Coins',
  '1601': 'Decentralized Exchange - AMM',
  '1602': 'Decentralized Exchange - Aggregator',
  '1603': 'Lending/Borrowing',
  '1604': 'Insurance',
  '1605': 'Yield Aggregator',
  '1606': 'Bridges',
  '1607': 'Asset Management',
  '1608': 'Derivatives',
  '1701': 'Others',
};
export const entitySubtypeWithType = {
  '101': 'Exchange: Mandatory KYC and AML',
  '102': 'Exchange: Optional KYC and AML',
  '103': 'Exchange: Decentralized (DEX)',
  '104': 'Exchange: P2P',
  '105': 'Exchange: OTC',
  '106': 'Exchange: Inactive',
  '201': 'Mining Pool: Mining Pool',
  '301': 'Service: Payment Gateway',
  '302': 'Service: Financial Service',
  '303': 'Service: Wallet Provider',
  '304': 'Service: Marketplace',
  '305': 'Service: Shop',
  '306': 'Service: Bitcoin Faucet',
  '307': 'Service: Decentralized Finance (DeFi)',
  '308': 'Service: Custody',
  '309': 'Service: Cryptocurrency ATM',
  '401': 'Darknet: Child Sexual Abuse Material Vendor',
  '402': 'Darknet: Hitman Services',
  '403': 'Darknet: Stolen Accounts Vendors',
  '404': 'Darknet: Human Trafficking',
  '405': 'Darknet: Compromised Credit Cards',
  '406': 'Darknet: Narcotics and Controlled Substances Vendor',
  '407': 'Darknet: Weapons Vendor',
  '408': 'Darknet: Darknet Vendor Shop',
  '409': 'Darknet: Darknet Marketplace',
  '410': 'Darknet: Darknet Donation',
  '411': 'Darknet: Adult Content',
  '501': 'Gambling: Fiat and Crypto',
  '502': 'Gambling: Crypto only',
  '601': 'Coin Mixer: Centralized',
  '602': 'Coin Mixer: Decentralized',
  '701': 'Scam: Phishing',
  '702': 'Scam: Spam',
  '703': 'Scam: High Yield Investment Program',
  '704': 'Scam: Exit Scam',
  '705': 'Scam: Fake ICO Scam',
  '706': 'Scam: Impersonation Scam',
  '707': 'Scam: Fake service',
  '801': 'Extortion: Sextortion',
  '802': 'Extortion: Blackmail',
  '901': 'Malware: Ransomware',
  '902': 'Malware: Cryptojacking',
  '1001': 'Theft: Hack',
  '1002': 'Theft: Abuse',
  '1101': 'Donation: Charity (Non-political)',
  '1102': 'Donation: Project Donation',
  '1103': 'Donation: Charity (Political)',
  '1104': 'Donation: Tip Jar',
  '1201': 'Smart Contract Platform: ICO',
  '1202': 'Smart Contract Platform: DAPP',
  '1203': 'Smart Contract Platform: Token',
  '1301': 'Sanctions: OFAC',
  '1302': 'High Risk Organization: OFAC Cluster',
  '1303': 'Sanctions: Entity from Sanctioned Country',
  '1401': 'High Risk Organization: OFAC Cluster',
  '1402': 'High Risk Organization: Terrorist Organization',
  '1403': 'High Risk Organization: Criminal Organization',
  '1404': 'High Risk Organization: Frozen Assets',
  '1405': 'High Risk Organization: Ukraine Entities',
  '1406': 'High Risk Organization: High Risk Jurisdictions',
  '1407': 'High Risk Organization: High Risk Exchanges',
  '1501': 'Law Enforcement: Seized Coins',
  '1601': 'DeFi: Decentralized Exchange - AMM',
  '1602': 'DeFi: Decentralized Exchange - Aggregator',
  '1603': 'DeFi: Lending/Borrowing',
  '1604': 'DeFi: Insurance',
  '1605': 'DeFi: Yield Aggregator',
  '1606': 'DeFi: Bridges',
  '1607': 'DeFi: Asset Management',
  '1608': 'DeFi: Derivatives',
  '1701': 'Others: Others',
};
export const getEntityColor = (entityType: string) => entityTypes[entityType] || entityTypes.Others;
export const getEntityDescription = (entityType: string, entitySubType: string) =>
  (entityDescriptions[entityType] && entityDescriptions[entityType][entitySubType]) || '';

export const getOptionsRuleConditions = (type: number) => {
  const options = {};
  const riskTypesKeys = [
    'risk_types',
    'risk_types_1',
    'risk_types_2',
    'indirect_risk_types',
    'inactivity_types',
  ];
  riskTypesKeys.forEach((key) => {
    options[key] = Object.keys(conditionVariables[key].options).map((innerKey) => ({
      label: conditionVariables[key].options[innerKey],
      value: conditionVariables[key].options[innerKey],
      id: innerKey,
    }));
  });
  options['entity_types'] = Object.keys(entityTypes).map((entity, index) => ({
    label: entity,
    value: entity,
    id: index + 1,
  }));
  options['entity_names'] = [];
  options['entity_subtypes'] = Object.keys(entitySubtypeWithType).map((key) => ({
    label: entitySubtypeWithType[key],
    value: entitySubtypeWithType[key],
    id: key,
  }));
  options['sentiments'] = [
    { label: 'Neutral', value: 'Neutral', id: '0' },
    { label: 'Positive', value: 'Positive', id: '1' },
    { label: 'Mixed', value: 'Mixed', id: '2' },
    { label: 'Not Available', value: 'Not Available', id: '3' },
    { label: 'Negative', value: 'Negative', id: '-1' },
  ];
  options['threshold_amount_usd'] = [
    [18, 19, 20].includes(type)
      ? {
          label: 'for at least US$0.01',
          value: 'for at least US$0.01',
          id: '0.01',
        }
      : { label: 'for at least any USD amount', value: 'for at least any USD amount', id: '0' },
    { label: 'for at least US$1000.00', value: 'for at least US$1000.00', id: '1000.0' },
  ];
  options['upper_threshold_amount_usd'] = [
    { label: 'up to any USD amount', value: 'up to any USD amount', id: '0' },
    { label: 'at most US$1000.00', value: 'at most US$1000.00', id: '1000.0' },
  ];
  options['taint_percent_threshold'] = [
    { label: 'has at least 0% taint', value: 'has at least 0% taint', id: '0' },
    { label: 'has at least 10% taint', value: 'has at least 10% taint', id: '10' },
  ]; // between 0 to 99
  options['taint_percent_upper_threshold'] = [
    { label: 'up to any taint %', value: 'up to any taint %', id: '0' },
    { label: 'has at most 10% taint', value: 'has at most 10% taint', id: '10' },
  ]; // between 10 to 100%
  options['analysis_window'] = [
    { label: '5 days', value: '5 days', id: '5' },
    { label: '7 days', value: '7 days', id: '7' },
  ];
  options['transit_window'] = [
    { label: '300 minutes', value: '300 minutes', id: '300' },
    { label: '900 minutes', value: '900 minutes', id: '900' },
  ];
  options['analysis_window_2'] = [
    { label: '200 minutes', value: '200 minutes', id: '200' },
    { label: '300 minutes', value: '300 minutes', id: '300' },
  ];
  options['payment_amount_percent'] = [
    { label: 'at least 50%', value: 'at least 50%', id: '50' },
    { label: 'at least 90%', value: 'at least 90%', id: '90' },
  ];
  options['transaction_amount_threshold'] = [
    { label: 'at least US$ 1000', value: 'at least US$ 1000', id: '1000' },
    { label: 'at least US$ 2192', value: 'at least US$ 2192', id: '2192' },
  ];
  options['counterparty_threshold'] = [
    { label: 'at least 10', value: 'at least 10', id: '10' },
    { label: 'at least 35', value: 'at least 35', id: '35' },
  ];
  options['dormancy_window'] = [
    { label: '17 days', value: '17 days', id: '17' },
    { label: '36 days', value: '36 days', id: '36' },
  ];
  options['address_age'] = [
    { label: '7 days', value: '7 days', id: '7' },
    { label: '17 days', value: '17 days', id: '17' },
  ];
  options['lower_bound'] = [
    { label: 'US$ 100', value: 'US$ 100', id: '100' },
    { label: 'US$ 232', value: 'US$ 232', id: '232' },
  ];
  options['upper_bound'] = [
    { label: 'US$ 100', value: 'US$ 100', id: '100' },
    { label: 'US$ 232', value: 'US$ 232', id: '232' },
  ];
  options['transaction_count_threshold'] = [
    { label: '3 times', value: '3 times', id: '3' },
    { label: '5 times', value: '5 times', id: '5' },
  ];
  options['transaction_count_threshold_2'] = [
    { label: '3', value: '3', id: '3' },
    { label: '5', value: '5', id: '5' },
  ];
  options['transaction_count_upper_threshold'] = [
    { label: 'at most 3', value: '3', id: '3' },
    { label: 'at most 5', value: '5', id: '5' },
  ];
  options['lower_transaction_count_threshold'] = [
    { label: 'at least 1', value: '1', id: '1' },
    { label: 'at least 5', value: '5', id: '5' },
  ];
  options['multiplier_threshold'] = [
    { label: '5', value: '5', id: '5' },
    { label: '10', value: '10', id: '10' },
  ];
  return options;
};
